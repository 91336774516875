export function resizeListener() {
    // 定义设计图的尺寸 1280
    let designSize = 1400;
    // 获取 html 元素
    let html = document.documentElement;
    // 定义窗口的宽度
    let clientW = html.clientWidth;
    if(clientW<=1500){
      designSize = 1100;
    }
    if(clientW<=1050){
      designSize = 850;
    }
    if(clientW<=750){
      designSize = 650;
    }
    if(clientW<=650){
      designSize = 550;
    }
    if(clientW<=450){
      designSize = 500;
    }
    // html 的fontsize 大小
    let htmlRem = (clientW * 12) / designSize;
    html.style.fontSize = htmlRem + 'px';
    // console.log(clientW);
  }