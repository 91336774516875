import React from 'react';
import styled from "styled-components";
import a from '../../assets/op/a.png';
import b from '../../assets/op/b.png';
import opensea from '../../assets/op/opensea.png';
import metamask from '../../assets/op/metamask.png';
import opera from '../../assets/op/opera2.png';


const Card=styled.div`
margin:0 auto ;
text-align:center ;
font-family:Lato-Regular ;
h3{
    font-size:24px ;
    color:#f4f4f4 ;
    padding-bottom: 25px;
    @media screen and (max-width: 750px){
        font-size:18px ;
    }
}
 `
const Imgs=styled.div`
    display:flex ;
    justify-content:center ;
    column-gap:50px;
    height:45px ;
    @media screen and (max-width: 750px){
        column-gap:45px;
    }

    img{
    width:30px ;
    height:30px ;
    @media screen and (max-width: 750px){
        width:23px ;
        height:23px ;
    }
}
 `
const imgs=[b,opera,metamask,opensea,a]
export function LogoCard(){
    return (
        <Card>
            <h3>Supported</h3>
            <Imgs>
                {
                    imgs.map((v, index) => {
                        return <img src={v} key={index} alt="" />
                    })
                }
            </Imgs>

        </Card>
    )
}