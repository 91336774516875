import React from "react";
import { useWindowSize } from "react-use";
import styled from "styled-components";

import { footer_logolist } from "../../utils/getLogos";

const FooterContainer = styled.div`
  position:absolute;
  width: 100%;
  height: 70px;
  background: transparent;
  z-index: 100;
  bottom:60px;
  padding: 0 5vw;

  @media screen and (max-width: 1024px) {
    position: static;
    margin-top: 38px;
  }
  @media screen and (max-width: 750px) {
    position: static;
    margin-top: 15px;
    height: auto;
    padding-bottom:20px ;
  }
`;

const Container = styled.footer`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 440px){
    flex-direction:column ;
  }

`;

const Link = styled.a`
  color: #fff;
  &:hover {
    color: #fff;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  /* column-gap:20px; */

  font-size: 16px;
  font-family: "ApproachMonoTRIAL-Lt";
  font-weight: 300;
  color: #fff;
  line-height: 28px;

  a:not(:first-child),i {
    margin-left: 20px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    /* column-gap:14px; */
  }
  @media screen and (max-width: 750px) {
    font-size: 16px;
    height:60px ;
    /* column-gap:10px; */
  }

  i {
    width: 1px;
    height: 16px;
    background: #898888;
  }
`;
const LOGOS=styled.div`
    display: flex;
    img {
      width: 47px;
      height: auto;

      @media screen and (max-width: 1000px) {
        width: 40px;
      }
      @media screen and (max-width: 750px) {
        width: 47px;
      }
    }
 `
export function Footer3() {

  const { width } = useWindowSize();

  return (
    <FooterContainer >
      <Container>
        {width > 750 ? (
          <>
            {" "}
            <Links>
              <Link
                href="https://docs.star.co/terms-of-service"
                target="_blank"
              >
                Terms
              </Link>
              <i></i>
              <Link href="https://docs.star.co/privacy-policy" target="_blank">
                Privacy
              </Link>
              <i></i>
              <Link href="https://star.co" target="_blank">
                Star.co
              </Link>
            </Links>
            <LOGOS>
              <Link href="https://twitter.com/star_protocol" target="_blank">
                <img src={footer_logolist.twitter} alt="图片加载失败" />
              </Link>
              <Link href="https://discord.com/invite/KqR8Tr57Kw" target="_blank">
                <img src={footer_logolist.discode} alt="图片加载失败" />
              </Link>
              <Link href="https://star-protocol.medium.com" target="_blank">
                <img src={footer_logolist.medium} alt="图片加载失败" />
              </Link>
            </LOGOS>
          </>
        ) : (
          <>
            <LOGOS>
              <Link href="https://twitter.com/star_protocol" target="_blank">
                <img src={footer_logolist.twitter} alt="图片加载失败" />
              </Link>
              <Link href="https://discord.com/invite/KqR8Tr57Kw" target="_blank">
                <img src={footer_logolist.discode} alt="图片加载失败" />
              </Link>
              <Link href="https://star-protocol.medium.com" target="_blank">
                <img src={footer_logolist.medium} alt="图片加载失败" />
              </Link>
            </LOGOS>
            <Links>
              <Link
                href="https://docs.star.co/terms-of-service"
                target="_blank"
              >
                Terms
              </Link>
              <i></i>
              <Link href="https://docs.star.co/privacy-policy" target="_blank">
                Privacy
              </Link>
              <i></i>
              <Link href="https://star.co" target="_blank">
                Star.co
              </Link>
            </Links>
          </>
        )}
      </Container>
    </FooterContainer>
  );
}
